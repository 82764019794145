import React from 'react'
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { useAuth } from '../../context/auth';
import { getDataFromLocalStorage } from '../../utils/localStorage';

const SideBarLayout = ({ children }) => {
    const { logout, isAuthenticatedUser } = useAuth();
//console.log("isAuthenticatedUser:", isAuthenticatedUser);
    const handleLogout = async () => {
        const userData = await getDataFromLocalStorage('userData');
        const userDataParse = JSON.parse(userData);
        const header = `Bearer ${userDataParse.token}`;
        await logout(userDataParse.id, header);
    }
    return (
        <div className="dash-container">
            <aside>
                <nav>
                    <Nav as="ul">
                        {isAuthenticatedUser?.role === 'employer' ? (
                            <>
                                <Nav.Item as="li"><Nav.Link as={NavLink} to="#" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} >Dashboard</Nav.Link></Nav.Item>
                                <Nav.Item as="li"><Nav.Link as={NavLink} to="/company-profile" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} >Company Profile</Nav.Link></Nav.Item>
                                <Nav.Item as="li"><Nav.Link as={NavLink} to="/employer" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} >Post A New Job</Nav.Link></Nav.Item>
                                <Nav.Item as="li"><Nav.Link as={NavLink} to="/manage-jobs" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} >Manage Jobs</Nav.Link></Nav.Item>
                                <Nav.Item as="li"><Nav.Link as={NavLink} to="/all-applicants" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} >All Applicants</Nav.Link></Nav.Item>
                            </>
                        ) : (
                            <>
                                {/* <Nav.Item as="li"><Nav.Link as={NavLink} to="/freelancer-profile" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} >Profile</Nav.Link></Nav.Item> */}
                                <Nav.Item as="li"><Nav.Link as={NavLink} to="/edit-profile" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} >Profile</Nav.Link></Nav.Item>
                                <Nav.Item as="li"><Nav.Link as={NavLink} to="/jobs-list" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} >Jobs</Nav.Link></Nav.Item>
                                <Nav.Item as="li"><Nav.Link as={NavLink} to="/jobs-applied" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} >Applied jobs</Nav.Link></Nav.Item>
                            </>
                        )}
                        <Nav.Item as="li"><Nav.Link as={NavLink} to="#" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} onClick={handleLogout}>Logout</Nav.Link></Nav.Item>
                    </Nav>
                </nav>
            </aside>
            {children}
        </div>

    )
}

export default SideBarLayout