import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import SideBarLayout from '../../components/AuthHeader/sideBarLayout';
import { getDataFromLocalStorage, } from '../../utils/localStorage';
import { BASE_URL } from '../../apis/Helper';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../context/auth';

const FreelancerProfileScreen = () => {
    const { isAuthenticatedUser } = useAuth();

    const location = useLocation();

    const [formData, setFormData] = useState({});
    //console.log("formData: ", formData);
    useEffect(() => {
        const fetchData = async () => {
            const getFreelancerProfile = await getDataFromLocalStorage(`FreelancerProfile-${isAuthenticatedUser.userId}`);
            const freelancerProfile = JSON.parse(getFreelancerProfile);
            console.log("freelancerProfile: ", freelancerProfile)
            if (freelancerProfile) {
                setFormData({
                    id: freelancerProfile.id,
                    name: freelancerProfile.name,
                    skills: freelancerProfile.skills,
                    age: freelancerProfile.age,
                    mobile_number: freelancerProfile.mobile_number,
                    education: freelancerProfile.education,
                    current_job: freelancerProfile.current_job,
                    aadhar_card: freelancerProfile.aadhar_card
                });
            }
        };

        fetchData();
    }, [location]);

    return (
        <SideBarLayout>
            <main id='main'>
                <Container>
                    <Row className="justify-content-center">
                        <Col md="12" lg="8" xl="8" className="mt-5">
                            <Card
                                bg={'light'}
                                key={'Light'}
                                text={'dark'}
                                className="mb-2"
                            >
                                <Card.Body className="p-4 col-md-12">
                                    <h3 className='text-start'>Your Profile</h3>
                                    <div className="d-flex text-black">
                                        <div className="d-flex flex-shrink-0 align-items-center" style={{backgroundColor:'#f2f2f2'}}>
                                            <Card.Img
                                                style={{ width: '180px', borderRadius: '10px' }}
                                                src={`${BASE_URL}/useruploads/${formData.aadhar_card}`}
                                                alt='Generic placeholder image'
                                                fluid />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <Card.Title className='text-start text-capitalize'>{formData.name}, {formData.age}</Card.Title>
                                            <Card.Text className='text-start'><i className="fa-solid fa-square-phone text-primary me-2"></i> {formData.mobile_number}</Card.Text>
                                            <Card.Text className='text-start'><i className="fa-solid fa-user-graduate"></i> {formData.education}, <i className="fa-solid fa-user-tie"></i> {formData.current_job}</Card.Text>

                                            <div className="rounded-3 p-2 mb-2"
                                                style={{ backgroundColor: '#efefef' }}>
                                                <div>
                                                <Card.Title as="h4" className='text-start'>Skills</Card.Title>
                                                <Card.Text className='text-start'>{formData.skills}</Card.Text>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </main>
        </SideBarLayout>
    )
}

export default FreelancerProfileScreen