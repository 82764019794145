import React, { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import GoogleTranslate from '../GoogleTranslate';

const AuthHeader = () => {
    const location = useLocation();
    console.log("location: ", location);
    const loadCSS = (href) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = href;
        document.head.appendChild(link);
    }

    const unloadCss = (href) => {
        const links = document.querySelectorAll(`link[href="${href}"]`);
        links.forEach(link => link.parentNode.removeChild(link));
    }

     useEffect(() => {
        const bootsTrapCss = `${process.env.PUBLIC_URL}/css/bootstrap.min.css`;
        const styleCss = `${process.env.PUBLIC_URL}/css/style.css`;
        const mainStyleCss = `${process.env.PUBLIC_URL}/css/styles.css`;

        unloadCss(bootsTrapCss)
        unloadCss(styleCss)

        loadCSS(mainStyleCss);
        return () => {
            unloadCss(mainStyleCss)
        }
    }, [location]);
    return (
        <header>
            <nav>
                <div className="logo">UNNATI</div>
                <Nav as="ul">
                    <Nav.Item as="li"><Nav.Link as={NavLink} to="/" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} >Home</Nav.Link></Nav.Item>
                    <Nav.Item as="li"><Nav.Link as={NavLink} to="/about" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} >About Us</Nav.Link></Nav.Item>
                    {/* <Nav.Item as="li"><Nav.Link as={NavLink} to="/job-list" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} >UNNATI</Nav.Link></Nav.Item> */}
                    {/* <Nav.Item as="li"><Nav.Link as={NavLink} to="/timeline" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} >Blog</Nav.Link></Nav.Item> */}
                    <Nav.Item as="li"><Nav.Link as={NavLink} to="/contact" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} >Contact Us</Nav.Link></Nav.Item>
                </Nav>
                <Nav as="ul" className="navbar-links">
                    {/* <Nav.Item><Nav.Link className="account" href="/login">My Account</Nav.Link></Nav.Item> */}
                    <GoogleTranslate path={location.pathname}/>
                </Nav>
            </nav>
        </header>

    )
}

export default AuthHeader