import React from 'react'
import { useLocation } from 'react-router-dom';
import MainLayout from './mainLayout';
import AuthLayout from './authLayout';


const Layout = () => {
  const location = useLocation();
  const specialHeaderPaths = [
    '/company-profile', 
    '/employer', 
    '/manage-jobs', 
    '/all-applicants', 
    '/shortlisted-resumes', 
    '/freelancer-profile', 
    '/edit-profile', 
    '/jobs-list',
    '/jobs-applied',
    '/all-applicants'
  ];

  const renderHeader = () => {
    if (specialHeaderPaths.includes(location.pathname)) {
      return <AuthLayout />;
    } else {
      return <MainLayout />;
    }
  };
  return renderHeader();
}

export default Layout