import React from 'react'
import "./style.css"

const Button = (props) => {
    return (
        <a href={props.url} className={props?.outline ? `btn unnati-outline` : `btn unnati-btn`}>
            {props?.text && (props?.text)}
            {props?.icon &&(
                <i className={`fa-solid ${props?.icon}`}></i>
            )}
        </a>
    )
}
export default Button