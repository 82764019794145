import React from 'react';
import "./style.css"

function FAQ() {
    return (
        <>
            <div className="row">
                <div className="col-md-6 py-3">
                    <div className='d-flex'>
                        <div className="question-icon">
                            <span>?</span>
                        </div>
                        <div className="question-content text-start">
                            <h3>How long will it take to get a job through Unnati?</h3>
                            <p>It depends, but we share your information with employers immediately to make the process faster.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 py-3">
                    <div className='d-flex'>
                        <div className="question-icon">
                            <span>?</span>
                        </div>
                        <div className="question-content text-start">
                            <h3>How can I apply for jobs?</h3>
                            <p>Simply visit our website, fill out the form, and we will pass your information along to employers.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 py-3">
                    <div className='d-flex'>
                        <div className="question-icon">
                            <span>?</span>
                        </div>
                        <div className="question-content text-start">
                            <h3>In which schools is Unnati's career counseling and mentorship program available?</h3>
                            <p>Unnati's career counseling and mentorship program has already been launched in several government and private schools in Jaipur, and will soon be expanded to other cities.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 py-3">
                    <div className='d-flex'>
                        <div className="question-icon">
                            <span>?</span>
                        </div>
                        <div className="question-content text-start">
                            <h3>How can employers post job listings?</h3>
                            <p>Employers can register on our platform and post job listings easily.</p>
                        </div>
                    </div> 
                </div>
            </div>
            <div className="support">
                <p>Didn't find the answer you are looking for? <a href="/contact">Contact our support</a></p>
            </div>
        </>
    );
}

export default FAQ;
