// App.js
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { AuthProvider } from './context/auth'; // Import AuthProvider
import router from './Router'; // Assuming you have a separate file for routes

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} fallbackElement={<Fallback />} />
    </AuthProvider>
  );
}

export default App;

export function Fallback() {
  return (
    <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
      <div className="spinner-border text-primary" style={{width: '3rem', height: '3rem'}} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}
