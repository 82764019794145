import React from 'react'
//import "./style.css"

const Hero = () => {
    return (
        <section className="hero">
            <div className="hero-content">
                <div className=''>
                    <h1>Welcome to Unnati</h1>
                    <p>Our initiative to give direction to your dreams and connect you with employment. Here everyone will get opportunity, guidance, and path to success.</p>
                </div>
                <div className="job-openings">
                    <div className='row'>
                        <div className='col'>
                            <b>20+</b>
                            <p>Job Listings</p>
                        </div>
                        <div className='col'>
                            <b>100+</b>
                            <p>People employers</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <b>30+</b>
                            <p>Career counselling sessions,</p>
                        </div>
                        <div className='col'>
                            <b>15+</b>
                            <p>RTE students mentored</p>
                        </div>
                    </div>
                </div> 
            </div>
        </section>
    )
}

export default Hero