import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Hero from '../../components/Hero'
import Testimonial from '../../components/Testimonial'
import Button from '../../components/Button';
import Slides from '../../components/Slides';
import FAQ from '../../components/FAQ';
import { testimonial, slides, jobWeOffer } from './data'


const HomeScreen = () => {

  const listTestimonial = testimonial.map(data =>
    <Testimonial
      key={data.id}
      profession={data.profession}
      image={data.image}
      name={data.name}
      description={data.description}
    />
  );

  return (
    <>
      <Hero />
      {/* <div className="container-xxl bg-white p-0"> */}
      <div className="container-xxl py-5">
        <div className="container-lg">
          <h2 className="text-center mb-4 wow fadeInUp display-3" data-wow-delay="0.1s">Make a difference to society through employment—step up with us!</h2>
          <p>Discover job opportunities according to your interests and contribute to building a bright future.</p>
          <div className="d-inline-flex gap mb-4 mt-4">
            <Button url="/about" text='Learn More' icon={'fa-chevron-right'} />
            <Button url="/signup" text='Join Us' outline />
          </div>
          <Slides slides={slides} />
        </div>
      </div>
      <section className='bg-drak-grey-Blue dark'>
        <div className="container-xxl py-4">
          <h1 className="text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">Services we offer</h1>
          <OwlCarousel loop dots={false} responsive={
            {
              0: {
                items: 1,
              },
              700: {
                items: 2,
              },
              1000: {
                items: 3,
              }
            }
          } className="unnti-card-carousel">
            {jobWeOffer.map(job => {
              return (
                <div className='unnti-card'>
                  <div className='card'>
                    <div className='icon'><i class={job?.icon}></i></div>
                    <div className='title'>{job?.title}</div>
                    <div className='text'>{job?.text}</div>
                    <div className='image'>
                      <img src={job?.image} alt='' />
                    </div>
                    <div className='sub-text'>{job?.subText}</div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      </section>

      <div className="container-xxl py-5" data-wow-delay="0.1s">
        <h1 className="text-center mb-5">Testimonials</h1>

        <OwlCarousel center loop dots={false} responsive={
          {
            0: {
              items: 1,
            },
            700: {
              items: 2,
            },
            1000: {
              items: 3,
            }
          }
        } className="testimonial-carousel">
          {listTestimonial}
        </OwlCarousel>
      </div>
      <div className="container-xxl py-5" data-wow-delay="0.1s">
        <h1 className="title">Frequently Asked Questions</h1>
        <p className="subtitle">Find answers to common questions about Unnati and our services.</p>
        <FAQ />
      </div>
    </>
  )
}

export default HomeScreen