import React, { useEffect, useState } from 'react'
import SideBarLayout from '../../components/AuthHeader/sideBarLayout';
import { getDataFromLocalStorage, } from '../../utils/localStorage';
import { useLocation } from 'react-router-dom';
import { getUserApplications } from '../../apis/Apis';
import AppliedJobList from '../../components/JobList/applied';

const FreelancerAppliedJobsScreen = () => {
    const location = useLocation();
    const [formData, setFormData] = useState({});
    console.log("formData: ", formData);
    useEffect(() => {
        const fetchData = async () => {
            const userData = await getDataFromLocalStorage('userData');
            const userDataParse = JSON.parse(userData);
            const header = `Bearer ${userDataParse.token}`;
            const response = await getUserApplications(userDataParse.id, header)
            console.log("response rrr; ", response.data);
            if (response.data.success) {
                setFormData(response.data.data);
            }
        };

        fetchData();
    }, [location]);
    return (
        <SideBarLayout>
            <main id='main'>
                <div className="container-xxl py-5">
                    <div className="container">
                        <h1 className="alert alert-success mt-3 mb-5 wow fadeInUp" data-wow-delay="0.1s">Applied Jobs</h1>
                        <div className="tab-class text-center wow fadeInUp" data-wow-delay="0.3s">
                            <div className="tab-content">
                                <div id="jobsList" className="tab-pane fade show p-0 active">
                                    <AppliedJobList formData={formData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </SideBarLayout>
    )
}

export default FreelancerAppliedJobsScreen