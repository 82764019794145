import React from 'react';
import { BASE_URL } from '../../apis/Helper';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/esm/Image';

const AppliedJobList = ({ formData }) => {

    return (
        <>
            {formData && formData.length > 0 && formData.map(job => (
                <div key={job.id} className='job-item p-4 mb-4'>
                    <div className="row g-4">
                        <div className="col-sm-12 col-md-8 d-flex align-items-center">
                            <Image className="flex-shrink-0 img-fluid border rounded" src={`${BASE_URL}/useruploads/${job.logo}`} alt="Company Logo" style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
                            <div className="text-start ps-4">
                                <h5 className="mb-3">{job.title}</h5>
                                <span className="text-truncate me-3"><i className="fa fa-map-marker-alt text-primary me-2"></i>{job.city}, {job.country}</span>
                                <span className="text-truncate me-3"><i className="fa fa-clock text-primary me-2"></i>{job.job_type}</span>
                                <span className="text-truncate me-0"><i className="fa fa-money-bill-alt text-primary me-2"></i>{job.salary}</span>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center">
                            <div className="d-flex mb-3">
                                <Button className="btn btn-primary text-capitalize">
                                    {job.status}
                                </Button>
                            </div>
                            <small className="text-truncate"><i className="far fa-calendar-alt text-primary me-2"></i>Deadline: {new Date(job.deadline).toLocaleDateString()}</small>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default AppliedJobList;