import { commonrequest } from "./ApisCall";
import { BASE_URL } from "./Helper";

const BACKEND_URL = BASE_URL;

// export const registerfunction = async(data)=>{
//     return await commonrequest("POST", `${BACKEND_URL}/user/register`, data)
// }

export const userRegister = async(data,header)=>{
    return await commonrequest("POST", `${BACKEND_URL}/user/signup`, data,header)
}
export const userLogin = async(data,header)=>{
    return await commonrequest("POST", `${BACKEND_URL}/user/login`, data,header)
}
export const userValid = async(header)=>{
    console.log(header);
    return await commonrequest("GET", `${BACKEND_URL}/user/uservalid`,"",header)
}
export const Updateuser = async(id,data,header)=>{
    return await commonrequest("PUT", `${BACKEND_URL}/user/userUpdate/${id}`,data,header)
}
export const Updateprofile = async(id,data,header)=>{
    return await commonrequest("PUT", `${BACKEND_URL}/user/profileUpdate/${id}`,data,header)
}
export const userLogout = async(id, header)=>{
    //console.log(header);
    return await commonrequest("PUT", `${BACKEND_URL}/user/userlogout/${id}`,"", header)
}

//Job
export const jobGet = async(id, header) => {
    return await commonrequest("GET", `${BACKEND_URL}/job/${id}`,"",header)
}
export const jobPost = async(data, header) => {
    return await commonrequest("POST", `${BACKEND_URL}/job/post`, data,header)
}
export const jobUpdateById = async(id, data, header) => {
    return await commonrequest("PUT", `${BACKEND_URL}/job/update/${id}`, data,header)
}
export const jobGetById = async(id, header) => {
    return await commonrequest("GET", `${BACKEND_URL}/job/get/${id}`, '', header)
}
export const jobGetByUserId = async(id, header) => {
    return await commonrequest("GET", `${BACKEND_URL}/job/get/user/${id}`, '', header)
}
export const jobDelete= async(id, header) => {
    return await commonrequest("POST", `${BACKEND_URL}/job/delete/${id}`, "",header)
}

//Company profile
export const companyGet = async(data, header) => {
    return await commonrequest("GET", `${BACKEND_URL}/company/`, data, header)
}
export const companyPost = async(data, header) => {
    return await commonrequest("POST", `${BACKEND_URL}/company/post`, data, header)
}
export const companyUpdateById = async(id, data, header) => {
    return await commonrequest("PUT", `${BACKEND_URL}/company/update/${id}`, data, header)
}
export const companyGetById = async(userID, data, header) => {
    return await commonrequest("GET", `${BACKEND_URL}/company/get/${userID}`, data, header)
}

//Freelancer profile
export const freelancerGet = async(data, header) => {
    return await commonrequest("GET", `${BACKEND_URL}/freelancer/`, data, header)
}
export const FreelancerPost = async(data, header) => {
    return await commonrequest("POST", `${BACKEND_URL}/freelancer/post`, data, header)
}
export const freelancerUpdateById = async(id, data, header) => {
    return await commonrequest("PUT", `${BACKEND_URL}/freelancer/update/${id}`, data, header)
}
export const freelancerGetById = async(userID, data, header) => {
    return await commonrequest("GET", `${BACKEND_URL}/freelancer/get/${userID}`, data, header)
}

//application
export const applyForJob = async(data, header) => {
    return await commonrequest("POST", `${BACKEND_URL}/applications/jobApply`, data, header)
}
export const getApplicationStatus = async(data, header) => {
    return await commonrequest("GET", `${BACKEND_URL}/applications/status`, data, header)
}
export const getJobApplications = async(id, header) => {
    return await commonrequest("GET", `${BACKEND_URL}/applications/job/${id}`, "", header)
}
export const getUserApplications = async(id, header) => {
    return await commonrequest("GET", `${BACKEND_URL}/applications/user/${id}`, "", header)
}
export const updateApplicationStatus = async(data, header) => {
    return await commonrequest("PUT", `${BACKEND_URL}/applications/update/status`, data, header)
}
// export const companyDelete= async(data, header) => {
//     return await commonrequest("POST", `${BACKEND_URL}/company/delete`, data,header)
// }

console.log(userValid);