import React from 'react';
// import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/auth';

const PrivateRoute = ({ element, requiredRole }) => {
  
  const { isAuthenticated, isAuthenticatedUser } = useAuth();
  console.log("isAuthenticated : ", isAuthenticated);
  if (!isAuthenticated) {
    window.location.href='/login';
    //return <Navigate to="/login" replace />;
    return
  }

  if (requiredRole && isAuthenticatedUser?.role !== requiredRole) {
    window.location.href='/unauthorized';
    return
    //return <Navigate to="/unauthorized" replace />;
  }

  return element;
  // return isAuthenticated ? (
  //   if (requiredRole && isAuthenticatedUser !== requiredRole) {
  //     <Navigate to="/404" replace />;
  //   }
  //   element
  // ) : (
  //   <Navigate to="/login" replace />
  // );
};

export default PrivateRoute;
