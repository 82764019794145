import React, { useEffect, useState } from 'react'
import SideBarLayout from '../../components/AuthHeader/sideBarLayout';
import { getDataFromLocalStorage, } from '../../utils/localStorage';
import { useLocation } from 'react-router-dom';
import { getJobApplications, updateApplicationStatus } from '../../apis/Apis';
import AppliedApplicants from '../../components/JobList/appliedApplicants';

const AppliedApplicantsScreen = () => {
    const location = useLocation();
    const [formData, setFormData] = useState({});
    const [userData, setUserData] = useState(null);
    //console.log("formData: ", formData);
    useEffect(() => {
        const fetchData = async () => {
            const userData = await getDataFromLocalStorage('userData');
            const userDataParse = JSON.parse(userData);
            setUserData({
                token: userDataParse.token,
                userId: userDataParse.id,
            });
            const header = `Bearer ${userDataParse.token}`;
            const response = await getJobApplications(userDataParse.id, header)
            //console.log("response rrr; ", response.data);
            if (response.data.success) {
                setFormData(response.data.data);
            }
        };

        fetchData();
    }, [location]);

    const userApplyForJob = async (props) => {
        //console.log("jobId, applicantID, status, index: ", props);
        const formData = new URLSearchParams();
        formData.append('job_id', props.id);
        formData.append('user_id', props.applicant);
        formData.append('status', props.status);

        const header = `Bearer ${userData.token}`;
        const response = await updateApplicationStatus(formData, header);
        //console.log(response.data)
        setFormData(prevJobs =>
            prevJobs.map(job =>
                job.job_id === props.id
                    ? {
                        ...job,
                        applicants: job.applicants.map((applicant, i) =>
                            i === props.index
                                ? { ...applicant, job_applied_status: props.status }
                                : applicant
                        )
                    }
                    : job
            )
        );
    }
    return (
        <SideBarLayout>
            <main id='main'>
                <div className="container-xxl py-5">
                    <div className="container">
                        <h1 className="alert alert-success mt-3 mb-5 wow fadeInUp" data-wow-delay="0.1s">Applied Applicants</h1>
                        <div className="tab-class text-center wow fadeInUp" data-wow-delay="0.3s">
                            <div className="tab-content">
                                <div id="jobsList" className="tab-pane fade show p-0 active">
                                    <AppliedApplicants formData={formData} applyForJob={userApplyForJob} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </SideBarLayout>
    )
}

export default AppliedApplicantsScreen