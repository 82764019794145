import React, { useState } from 'react';
import { BASE_URL } from '../../apis/Helper';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/esm/Image';

const JobList = ({ formData, applyForJob }) => {
    const [expandedJobId, setExpandedJobId] = useState(null);

    const toggleDetails = (jobId) => {
        setExpandedJobId(prevJobId => prevJobId === jobId ? null : jobId);
    };

    return (
        <>
            {formData && formData.length > 0 && formData.map(job => (
                <div key={job.id} className='job-item p-4 mb-4'>
                    <div className="row g-4">
                        <div className="col-sm-12 col-md-8 d-flex align-items-center">
                            <Image className="flex-shrink-0 img-fluid border rounded" src={`${BASE_URL}/useruploads/${job.logo}`} alt="Company Logo" style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
                            <div className="text-start ps-4">
                                <h5 className="mb-3">{job.title}</h5>
                                <span className="text-truncate me-3"><i className="fa fa-map-marker-alt text-primary me-2"></i>{job.city}, {job.country}</span>
                                <span className="text-truncate me-3"><i className="fa fa-clock text-primary me-2"></i>{job.job_type}</span>
                                <span className="text-truncate me-0"><i className="fa fa-money-bill-alt text-primary me-2"></i>{job.salary}</span>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center">
                            <div className="d-flex mb-3">
                                <Button className="btn btn-light btn-square me-3" onClick={() => toggleDetails(job.id)}>
                                    <i className={`fa ${expandedJobId === job.id ? 'fa-chevron-up' : 'fa-chevron-down'} text-primary`}></i>
                                </Button>
                                <Button className="btn btn-primary" onClick={() => job.has_applied ? null : applyForJob(job.id)}>
                                    {job.has_applied ? 'Applied' : 'Apply Now'}
                                </Button>
                            </div>
                            <small className="text-truncate"><i className="far fa-calendar-alt text-primary me-2"></i>Deadline: {new Date(job.deadline).toLocaleDateString()}</small>
                        </div>
                    </div>
                    {expandedJobId === job.id && (
                        <div className="job-details">
                            <p><strong>Company Name:</strong> {job.company_name}</p>
                            <p><strong>Email:</strong> {job.email}</p>
                            <p><strong>Description:</strong> {job.description}</p>
                            <p><strong>Phone:</strong> {job.phone}</p>
                            <p><strong>Website:</strong> <a href={job.website} target="_blank" rel="noopener noreferrer">{job.website}</a></p>
                        </div>
                    )}
                </div>
            ))}
        </>
    );
};

export default JobList;