// router.js
import * as React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Layout from '../components/Layout';
import HomeScreen from "../pages/Home";
import AboutScreen from "../pages/About";
import ContactScreen from "../pages/Contact";
// import TimelineScreen from "../pages/Timeline";
import ErrorScreen from "../pages/404";
import LoginScreen from "../pages/Login";
import SignupScreen from "../pages/Signup";
import EmployerScreen from '../pages/Employer';
import CompanyProfileScreen from '../pages/CompanyProfile';
import ManageJobsScreen from '../pages/ManageJobs';
import FreelancerProfileScreen from '../pages/FreelancerProfile';
import PrivateRoute from '../components/ProtectedRoute';
import FreelancerProfileUpdateScreen from '../pages/FreelancerProfile/updateProfile';
import JobListScreen from '../pages/JobList';
import FreelancerAppliedJobsScreen from '../pages/FreelancerAppliedJobs';
import AppliedApplicantsScreen from '../pages/AppliedApplicants';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                index: true,
                loader: homeLoader,
                element: <HomeScreen />,
            },
            {
                path: "about",
                loader: homeLoader,
                element: <AboutScreen />,
            },
            {
                path: "contact",
                loader: homeLoader,
                element: <ContactScreen />,
            },
            // {
            //     path: "timeline",
            //     loader: homeLoader,
            //     element: <TimelineScreen />,
            // },
            {
                path: "login",
                loader: homeLoader,
                element: <LoginScreen />,
            },
            {
                path: "signup",
                loader: homeLoader,
                element: <SignupScreen />,
            },
            {
                path: 'employer',
                element: <PrivateRoute element={<EmployerScreen />} requiredRole="employer"/>, 
            },
            {
                path: 'company-profile',
                element: <PrivateRoute element={<CompanyProfileScreen />} requiredRole="employer"/>,
            },
            {
                path: 'manage-jobs',
                element: <PrivateRoute element={<ManageJobsScreen />} requiredRole="employer"/>,
            },
            {
                path: 'all-applicants',
                element: <PrivateRoute element={<AppliedApplicantsScreen />} requiredRole="employer"/>,
            },
            {
                path: 'freelancer-profile',
                element: <PrivateRoute element={<FreelancerProfileScreen />} requiredRole="freelancer"/>,
            },
            {
                path: 'edit-profile',
                element: <PrivateRoute element={<FreelancerProfileUpdateScreen />} requiredRole="freelancer"/>,
            },
            {
                path: 'jobs-list',
                element: <PrivateRoute element={<JobListScreen />} requiredRole="freelancer"/>,
            },
            {
                path: 'jobs-applied',
                element: <PrivateRoute element={<FreelancerAppliedJobsScreen />} requiredRole="freelancer"/>,
            },
            
            {
                path: "*",
                element: <ErrorScreen />,
            },
        ],
    },
]);

export default router;

if (import.meta.hot) {
    import.meta.hot.dispose(() => router.dispose());
}

export function sleep(n = 500) {
    return new Promise((r) => setTimeout(r, n));
}

export async function homeLoader() {
    await sleep();
    return {
        date: new Date().toISOString(),
    };
}
