import React, { useEffect, useState } from 'react'
import SideBarLayout from '../../components/AuthHeader/sideBarLayout';
import { getDataFromLocalStorage, } from '../../utils/localStorage';
import { useLocation } from 'react-router-dom';
import { jobGet, applyForJob } from '../../apis/Apis';
import JobList from '../../components/JobList';

const JobListScreen = () => {
    const location = useLocation();
    const [formData, setFormData] = useState({});
    const [userData, setUserData] = useState(null);
    console.log("formData jhhj: ", formData);
    useEffect(() => {
        const fetchData = async () => {
            const userData = await getDataFromLocalStorage('userData');
            const userDataParse = JSON.parse(userData);
            //console.log("userDataParse: ", userDataParse)
            setUserData({
                token: userDataParse.token,
                userId: userDataParse.id,
            });
            const header = `Bearer ${userDataParse.token}`;
            const response = await jobGet(userDataParse.id, header)
            console.log("response rrr; ", response);
            if (response.data.success) {
                setFormData(response.data.data);
            }
        };

        fetchData();
    }, [location]);
    const userApplyForJob = async (id) => {
        const formData = new URLSearchParams();
        formData.append('job_id', id);
        formData.append('user_id', userData.userId);

        const header = `Bearer ${userData.token}`;
        const response = await applyForJob(formData, header);
        console.log(response.data)
        setFormData(prevJobs =>
            prevJobs.map(job =>
                job.id === id ? { ...job, has_applied: 1 } : job
            )
        );
    }
    return (
        <SideBarLayout>
            <main id='main'>
                <div className="container-xxl py-5">
                    <div className="container">
                        <h1 className="alert alert-success mt-3 mb-5 wow fadeInUp" data-wow-delay="0.1s">Job Listing</h1>
                        <div className="tab-class text-center wow fadeInUp" data-wow-delay="0.3s">
                            {/* <div className="container-fluid bg-primary mb-5 wow fadeIn" data-wow-delay="0.1s"
                                style={{ padding: '35px' }}>
                                <div className="container">
                                    <div className="row g-2">
                                        <div className="col-md-10">
                                            <div className="row g-2">
                                                <div className="col-md-4">
                                                    <input type="text" className="form-control border-0" placeholder="Keyword" />
                                                </div>
                                                <div className="col-md-4">
                                                    <select className="form-select border-0">
                                                        <option selected>Category</option>
                                                        <option value="1">Category 1</option>
                                                        <option value="2">Category 2</option>
                                                        <option value="3">Category 3</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-4">
                                                    <select className="form-select border-0">
                                                        <option selected>Location</option>
                                                        <option value="1">Location 1</option>
                                                        <option value="2">Location 2</option>
                                                        <option value="3">Location 3</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <button className="btn btn-dark border-0 w-100">Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="tab-content">
                                <h1 className="text-center"> </h1>
                                <div id="jobsList" className="tab-pane fade show p-0 active">
                                    <JobList formData={formData} applyForJob={userApplyForJob} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </SideBarLayout>
    )
}

export default JobListScreen