import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import { useAuth } from '../../context/auth';
import { useNavigate } from 'react-router-dom';
//import './style.css'


const SignupScreen = () => {

    const { register } = useAuth();
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({ phone: '', password: '' });
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('');
    const [redirect, setRedirect] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    useEffect(() => {
        if (redirect) {
            const timer = setTimeout(() => {
                navigate('/login');
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [redirect, navigate]);

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        if (type === 'radio') {
            setCredentials({ ...credentials, [name]: value });
        } else {
            setCredentials({ ...credentials, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await register(credentials);
        if (!response.success) {
            setError(response.message);
            return;
        }
        setSuccess(response.message);
        setRedirect(true);
    };
    return (
        <>
            <div className="signup-container">
                <div className="form-container">
                    <form id="signupForm" onSubmit={handleSubmit}>
                        <h2 className="form-header">Signup</h2>
                        {success && <div className="alert alert-success mt-3">{success}</div>}
                        {error && <div className="alert alert-danger mt-3">{error}</div>}
                        {/* <div className="form-group">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={credentials.email}
                                onChange={handleChange}
                                placeholder="Email" required
                            />
                        </div> */}
                        <div className="form-group">
                            <label className='text-start' for="phone">Phone Number</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={credentials.phone}
                                onChange={handleChange}
                                required />
                        </div>
                        <div className="form-group">
                            <input
                                type={passwordVisible ? 'text' : 'password'}
                                name="password"
                                id="password"
                                value={credentials.password}
                                onChange={handleChange}
                                placeholder="Create password"
                                required
                            />
                            <span className="eye-icon" onClick={togglePasswordVisibility}><i className="far fa-eye"></i></span>
                        </div>
                        <div className="form-group form-radio align-items-start">
                            <label >
                                <input
                                    type="radio"
                                    name="role"
                                    value="employer"
                                    checked={credentials.role === 'employer'}
                                    onChange={handleChange}
                                    required
                                /> Employer
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="role"
                                    value="freelancer"
                                    checked={credentials.role === 'freelancer'}
                                    onChange={handleChange}
                                    required
                                /> Employee
                            </label>
                        </div>
                        <div className="form-group">
                            <Button type="submit" className="form-button">Signup</Button>
                        </div>
                        <div className="form-group form-link">
                            <p>Already have an account? <a href="/login">Login</a></p>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default SignupScreen