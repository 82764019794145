import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../Header'
import Footer from '../Footer'

const MainLayout = ({ children }) => {

    return (
        <>
            <Header />
            <main>
                <Outlet />
                {children}
            </main>
            <Footer />
        </>
    )
}

export default MainLayout