import React from 'react'
import Breadcrumb from '../../components/Breadcrumb';
import TeamCard from '../../components/Team';
import { teamMember } from './data'


const AboutScreen = () => {

  const listTeamMember = teamMember.map(data =>
    <TeamCard
      key={data.id}
      profession={data.profession}
      image={data.image}
      name={data.name}
    />
  );

  return (
    <>
      <Breadcrumb title={'About Us'} />
      <div className="container-xxl py-5">
        <div className="container">
          <div className="wow fadeIn" data-wow-delay="0.5s">
            <h1 className="text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">Our Mission</h1>
            <p>
              Unnati <b>(उन्नति)</b> means "progress" or "advancement." Our mission is to facilitate progress for low-skilled and semi-skilled laborers who lack formal education by connecting them with job opportunities that can transform their lives.
              This initiative represents "Ek Naya Prayas" <b>(एक नया प्रयास)</b> (A new endeavor) to simplify the job search process for those who face barriers in finding suitable employment. Employers looking for dedicated workers, whether for businesses or personal needs, can directly access a pool of talented individuals through our platform.
              Unnati focuses on empowering a marginalized community of workers, providing them with the support and resources they need to succeed in the workforce. Our organization is committed to enhancing the livelihoods of these individuals by partnering with various local businesses and organizations, ensuring that they have access to meaningful and sustainable employment opportunities.
            </p>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="about-container">
            <h2>Our Team</h2>
            <hr/>
              <div className="members row">
                {listTeamMember}
              </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutScreen