export const jobWeOffer = [
    {
        id:1,
        icon: 'fa-solid fa-handshake',
        title: 'Job Opportunities for All',
        text: 'Unnati brings the right opportunities for you, understanding your capabilities. We connect companies and provide a safe and inclusive workplace with on-the-job training.',
        subText: 'Our platform ensures that job seekers find the right opportunities that match their skills and experience.',
        image:'./img/jobs/2.jpg'
    },
    {
        id:2,
        icon: 'fa-solid fa-users', 
        title: 'Career Counselling',
        text: 'We provide students from government and private schools with the right guidance and skill development for their future, so that they can realize their dreams.',
        subText: 'From training programs to community events, we are here to support your journey.',
        image:'./img/jobs/3.jpg'
    },
    {
        id:3,
        icon: 'fa-solid fa-building',
        title: 'Mentorship for RTE students',
        text: 'Our experienced mentors help RTE students reach their goals through personalized support, coupled with structured and inspirational guidance.',
        subText: 'Our mentors help RTE students achieve their goals with personalized support and inspiring guidance.',
        image:'./img/jobs/4.jpg'
    },
    
]

export const testimonial = [
    {
        id:1,
        image: './img/testimonial-2.jpg',
        name: 'Mohan Kumar, Jaipur',
        description: 'I always thought that it would be difficult for me to get a job without a degree, but Unnati broke this illusion. He connected me with the right employer and today I am doing a stable job.',
        profession: 'Profession'
    },
    {
        id:2,
        image: './img/testimonial-4.jpg',
        name: 'Payal Verma, Jaipur',
        description: 'Unnati`s career counseling session was extremely helpful for my son. The guidance he received gave him clarity about his career and now he is moving in the right direction.',
        profession: 'Profession'
    },
    {
        id:3,
        image: './img/testimonial-2.jpg',
        name: 'Radhika Sharma, Jaipur',
        description: 'Unnati helped me get my first job, even without any experience. They not only hired me but also trained me so that I could work with confidence.',
        profession: 'Profession'
    },
    // {
    //     id:4,
    //     image: './img/testimonial-3.jpg',
    //     name: 'Client Four',
    //     description: 'Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam',
    //     profession: 'Profession'
    // },
];

export const slides = [
    {
        id:1,
        image: './img/jobs/2.jpg',
    },
    {
        id:2,
        image: './img/jobs/3.jpg',
    },
    {
        id:3,
        image: './img/jobs/4.jpg',
    },
    {
        id:4,
        image: './img/jobs/5.jpg',
    },
];