import React from 'react'
import Image from 'react-bootstrap/Image'
//import "./style.css"

const Testimonial = (props) => {
    return (
        <div className="testimonial-item bg-light rounded p-4">
            <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
            <p>{props?.description}</p>
            <div className="d-flex align-items-center">
                {props?.image && (<Image className="img-fluid flex-shrink-0 rounded" src={props?.image} style={{width: '50px', height: '50px'}} />)}
                    <div className="ps-3">
                        {props?.name && (<h5 className="mb-1">{props.name}</h5> )}
                        {props?.profession && (<small>{props.profession}</small>)}
                    </div>
            </div>
        </div>
    )
}

export default Testimonial