import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Breadcrumb from '../../components/Breadcrumb';


const ContactScreen = () => {


  return (
    <>
      <Breadcrumb title={'Contact Us'} />
      <div className="container-xxl py-5">
        <div className="container">
          
          <div className="row g-4">
            <div className="col-md-6">
            <h1 className="text-start mb-5 wow fadeInUp" data-wow-delay="0.1s">Contact For Any Query</h1>
              <div className="row gy-4">
                <div className="col-md-12 wow fadeIn" data-wow-delay="0.1s">
                  <div className="d-flex align-items-center bg-light rounded">
                    <div className="bg-white border rounded d-flex flex-shrink-0 align-items-center justify-content-center me-3" style={{width: '45px', height: '45px'}}>
                      <i className="fa fa-map-marker-alt text-primary"></i>
                    </div>
                    <span>JPIS, Mahapura, Jaipur India</span>
                  </div>
                </div>
                <div className="col-md-12 wow fadeIn" data-wow-delay="0.3s">
                  <div className="d-flex align-items-center bg-light rounded">
                    <div className="bg-white border rounded d-flex flex-shrink-0 align-items-center justify-content-center me-3" style={{width: '45px', height: '45px'}}>
                      <i className="fa fa-envelope-open text-primary"></i>
                    </div>
                    <span>organizationunnati@gmail.com</span>
                  </div>
                </div>
                {/* <div className="col-md-4 wow fadeIn" data-wow-delay="0.5s">
                  <div className="d-flex align-items-center bg-light rounded p-4">
                    <div className="bg-white border rounded d-flex flex-shrink-0 align-items-center justify-content-center me-3" style={{width: '45px', height: '45px'}}>
                      <i className="fa fa-phone-alt text-primary"></i>
                    </div>
                    <span>+91 9530376984</span>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <iframe className="position-relative rounded w-100 h-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
                frameBorder={0} style={{minHeight: '400px', border:0}} allowFullScreen="" aria-hidden="false"
                tabIndex="0" title="Google Maps of New York, USA"></iframe>
            </div> */}
            <div className="col-md-6">
              <div className="wow fadeInUp" data-wow-delay="0.5s">
                <p className="mb-4">The contact form is currently inactive. Get a functional and working contact form with Ajax & PHP in a few minutes. Just copy and paste the files, add a little code and you're done. <a href="https://htmlcodex.com/contact-form">Download Now</a>.</p>
                <form>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <Form.Control type="text" className="form-control" id="name" placeholder="Your Name" />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <Form.Control type="email" className="form-control" id="email" placeholder="Your Email" />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <Form.Control type="text" className="form-control" id="subject" placeholder="Subject" />
                        <label htmlFor="subject">Subject</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <Form.Control as="textarea" rows={3} className="form-control" placeholder="Leave a message here" id="message" />
                        <Form.Label htmlFor="message">Message</Form.Label>
                      </div>
                    </div>
                    <div className="col-12">
                      <Button className="btn btn-primary w-100 py-3" type="submit">Send Message</Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactScreen