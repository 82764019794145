import React, { useEffect } from 'react';

const GoogleTranslate = (path) => {

  useEffect(() => {
    // Check if the script is already added
    if (!document.querySelector('script[src="https://cdn.gtranslate.net/widgets/latest/fn.js"]')) {
      const script = document.createElement('script');
      script.src = "https://cdn.gtranslate.net/widgets/latest/fn.js";
      script.defer = true;
      document.body.appendChild(script);
    }

    // Initialize gtranslate settings
    window.gtranslateSettings = {
      default_language: "en",
      languages: ["en", "hi"],
      wrapper_selector: ".gtranslate_wrapper",
      flag_style: "2d"
    };
  }, [path]);

  return <div className="gtranslate_wrapper"></div>;
};

export default GoogleTranslate;
