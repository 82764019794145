import React from 'react'
import { Outlet } from 'react-router-dom'
import AuthHeader from '../AuthHeader'

const AuthLayout = ({ children }) => {

    return (
        <>
            <AuthHeader />
            <main>
                <Outlet />
                {children}
            </main>
        </>
    )
}

export default AuthLayout