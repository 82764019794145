import React, { createContext, useContext, useEffect, useState } from 'react';
import { userLogin, userRegister, userLogout } from '../apis/Apis';
import { saveDataToLocalStorage, getDataFromLocalStorage, removeDataFromLocalStorage } from '../utils/localStorage';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticatedUser, setIsAuthenticatedUser] = useState(null);

  useEffect(() => {

    const data = getDataFromLocalStorage('userData');
    //console.log("Retrieved data:", data); // Add this line
    const userData = JSON.parse(data);
    if (userData && userData.token) {
      setIsAuthenticated(true);
      setIsAuthenticatedUser({userId: userData.id, role: userData.role})
    } else {
      setIsAuthenticated(false);
      setIsAuthenticatedUser(null)
    }
    setLoading(false);
  }, []);
  const login = async (credentials) => {
    const response = await userLogin(credentials, '');
    //console.log("response 112", response.data);
    if (response.data.success) {
      setIsAuthenticated(true)
      setIsAuthenticatedUser({userId: response.data.userdata.id, role: response.data.userdata.role})
      const user = {
        id: response.data.userdata.id,
        email: response.data.userdata.email,
        phone: response.data.userdata.phone,
        role: response.data.userdata.role,
        token: response.data.token
      };
      saveDataToLocalStorage('userData', JSON.stringify(user))
      return response.data;
    }
    //console.log("response rjhfgejrgv", response)
    return { success: false, message: response.data.message }
  };

  const register = async (credentials) => {
    const response = await userRegister(credentials, '');
    //console.log("response 112", response);
    if (response.data.success) {
      return response.data;
    }
    return { success: false, message: response.data.message }
  }

  const logout = async (id, header) => {
    const response = await userLogout(id, header)
    if (response.data.success) {
      setIsAuthenticated(false);
      setIsAuthenticatedUser(null)
      removeDataFromLocalStorage('userData')
    }

  };

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a spinner or another loading indicator
  }

  return (
    <AuthContext.Provider value={{ isAuthenticatedUser, isAuthenticated, login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
